.input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 15rem;
}

.standard {
  width: 15rem;
}

.small {
  width: 7.5rem;
}

.compact {
  width: 8.5rem;
}

.full {
  width: 100%;
}

.disabled {
  background-color: #e0e0e0;
}

.input-error {
  border-color: #cf2030;
}
