.list-item {
  list-style: none;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-align: left;
  padding: 0.25rem 1rem;
}

.list-item:not(.highlighted):not(.disabled):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.list-item:not(.disabled):hover {
  cursor: pointer;
}

.highlighted {
  background-color: rgba(25, 118, 210, 0.08);
}

.disabled {
  color: #939ba1;
  cursor: default;
}
