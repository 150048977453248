.text {
  font-size: 0.875rem;
  color: #434e6b;
  margin-bottom: 2.5rem;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.red-button {
  background-color: #ff3541;
  margin-left: 1rem;
  transition: 0.1s;
}

.red-button:hover {
  background-color: #ff3541;
  opacity: 0.9;
}
