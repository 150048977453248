.standard {
  width: 76.5rem;
  min-height: 37.75rem;
}

.small {
  width: 40rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.25rem 0 1.5rem;
}

.no-title {
  justify-content: end;
}

.content {
  padding: 1rem 1.5rem;
}
