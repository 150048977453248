.paper {
  font-size: 0.75rem;
}

.popup-indicator-open {
  transform: none;
}

/* stylelint-disable-next-line */
.container :global(.MuiOutlinedInput-root.MuiInputBase-sizeSmall) {
  padding-right: 40px;
}
