.add-button {
  color: #51a0f7;
  padding-left: 0.3rem;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  font-weight: 700;
}

.add-button:hover {
  background: #fff;
}
