.accordion {
  border: 1px solid #e0e0e0;
  background-color: #fff;
}

.accordion.expanded {
  margin: 0;
}

.accordion-summary {
  height: 4.25rem;
  border-bottom: 1px solid #e0e0e0;
}

.title {
  text-transform: uppercase;
}
