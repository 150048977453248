.container {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.uploaded,
.errors,
.success,
.total {
  display: flex;
  padding: 10px;
  font-weight: 700;
  border-bottom: 1px solid #e0e0e0;
  flex: 1;
  min-width: 5rem;
}

.uploaded {
  min-width: 21rem;
}

.high-flex {
  flex: 10;
}

.uploaded-file {
  display: flex;
  align-items: center;
}

.file-icon {
  color: #51a0f7;
  margin-right: 0.5rem;
  font-size: 1.25rem;
}

.errors {
  color: #ff3541;
}

.success {
  color: #86c64f;
}

.import-with-errors {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 53, 65, 0.1);
  border: 1px solid #ff3541;
}

.download-button {
  color: #ff3541;
  border: 1px solid #ff3541;
  background-color: #fff;
  padding: 0.75rem 1.75rem;
  margin-left: 1rem;
}

.import-with-errors,
.success-import {
  padding: 0.5rem 1rem 0.625rem;
}

.success-import {
  background: rgba(134, 198, 79, 0.1);
  border: 1px solid #86c64f;
}

.plain-text {
  font-weight: 400;
}
