.text {
  margin: 0 auto;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper:hover .text {
  font-weight: 700;
}
