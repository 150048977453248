.item-button {
  padding-left: 1.5rem;
  padding-right: 1rem;
}

.item-button:hover {
  color: #74b3f9;
}

.caret-down {
  transform: rotate(180deg);
}

.title {
  color: #dcecfd;
}

.item-text {
  display: inline-flex;
  color: #f2f3f4;
}

.active,
.active:hover {
  background-color: #fff;
}

.active .item-text,
.chip {
  color: #51a0f7;
}

.chip {
  background-color: #fff;
  font-weight: bold;
  font-size: 0.625rem;
}

.active .chip {
  background-color: #e0e0e0;
}

.list-item-text {
  align-items: center;
  justify-content: space-between;
  display: flex;
}
