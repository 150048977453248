.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper:hover .text {
  font-weight: 700;
}

.amount {
  background-color: #e0e0e0;
  border-radius: 5rem;
  width: 1.875rem;
  height: 1.5rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin-left: 0.125rem;
}
