.title {
  font-size: 0.75rem;
  font-weight: 500;
}

.addition {
  color: #939ba1;
}

.wrapper-fields {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.field {
  width: 20%;
  margin-right: 1rem;
}

.list-items {
  list-style: none;
  padding: 0;
}
