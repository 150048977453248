.data-grid-page-container {
  height: 100vh;
}

.container {
  padding: 1.5rem;
}

.container,
.data-grid-page-container {
  display: flex;
  flex-direction: column;
}

.container,
.content {
  overflow: hidden;
  flex: 1;
}
