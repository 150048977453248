.input {
  width: 100%;
  margin-right: 0;
  padding-right: 0;
  position: relative;
}

.input-hidden-label {
  height: 0;
  visibility: hidden;
  padding-left: 4rem;
}
