.wrapper {
  display: flex;
  background-color: #ffe4c4;
  font-size: 0.75rem;
  line-height: 1;
  padding: 0.5rem;
  margin-bottom: 0.9rem;
}

.icon-wrapper {
  width: 20%;
}

.icon {
  color: #deb887;
}

.line {
  padding-bottom: 0.5rem;
}
