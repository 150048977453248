.container {
  display: flex;
  flex-direction: column;
  width: 36rem;
  margin-bottom: 1rem;
}

.label {
  display: block;
  color: #434e6b;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}

.alcohol-volume {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.delete-button {
  padding: 0;
  margin-top: 0.5rem;
  margin-right: 1rem;
}

.delete-button:hover {
  background-color: transparent;
}

.plus-button {
  border: 1px solid #51a0f7;
  border-radius: 2px;
  padding: 0.475rem;
  width: 2rem;
  height: 2rem;
}
