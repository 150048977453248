.wrapper {
  padding: 1.5rem;
}

.container {
  display: flex;
  justify-content: space-between;
}

.table-wrapper,
.edit-wrapper {
  flex: 1;
  border: 1px solid #dfdfdf;
  height: 85vh;
  overflow: scroll;
}

.edit-wrapper {
  margin-left: 1.25rem;
}

.edit-title {
  padding: 1.375rem 1.5rem 1.3125rem;
  border-bottom: 1px solid #dfdfdf;
}
