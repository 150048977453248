.input-container {
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
}

.selected-file,
.no-file {
  display: flex;
  flex: 1;
  align-items: center;
  border: 1px solid #e0e0e0;
  margin-right: 0.75rem;
  padding: 0.375rem 0.75rem;
}

.selected-file {
  justify-content: space-between;
  color: #434e6b;
}

.no-file {
  color: #939ba1;
}

.info-container,
.error-container {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.error-container {
  color: #ff3541;
}

.icon {
  font-size: 1rem;
  margin-right: 0.25rem;
}

.delete-icon {
  margin-right: 0.5rem;
  padding: 0;
}
