.dropdown {
  color: #fff;
  border-radius: 2px;
  margin: 0;
}

.flag {
  width: 1.75rem;
  height: 1.75rem;
  margin: 0 0.5rem 0 0;
  padding-top: -0.5rem;
}

.icon {
  fill: #fff;
}

.select {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.item:hover {
  background-color: inherit;
}
