.default {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.75rem;
  padding-bottom: 0.3rem;
}

.width {
  max-width: 5.5rem;
}

.tooltip {
  margin: 0;
}
