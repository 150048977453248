.dots {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.875rem;
}

.link {
  color: #51a0f7;
}
