.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text {
  font-size: 0.75rem;
  color: #939ba1;
  margin-right: 0.5rem;
}
