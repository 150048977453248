.link {
  font-weight: 400;
  padding: 0 0.25rem;
  line-height: 140%;
}

.link:hover {
  background: #fff;
}

.templates {
  font-weight: 400;
  display: block;
}

.templates:hover {
  background: #fff;
}
