.wrapper {
  position: relative;
}

.spinner {
  animation-duration: 550ms;
  position: absolute;
  left: 0;
}

.grey {
  color: #e0e0e0;
}

.light-grey {
  color: #eee;
}

.blue {
  color: #51a0f7;
}

.red {
  color: #f73441;
}
