.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.title-margin {
  margin-bottom: 0.5rem;
}

.condensed {
  margin-bottom: 1rem;
}
