.table-cell:first-child {
  padding: 0.5rem 1rem;
}

.table-cell {
  padding: 0.5rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
}

.cell-border-right {
  margin-right: 0.5rem;
  border-right: 1px solid #dfdfdf;
}

.cell-border-left {
  border-left: 1px solid #dfdfdf;
}

.table-row {
  background-color: #fff;
}

.highlight-row {
  background-color: #ededed;
}

.highlight-row-green {
  background-color: #c5e1a5; /* @mui/material/colors/lightGreen[200] */
}

.highlight-row-yellow {
  background-color: #fff59d; /* @mui/material/colors/yellow[200] */
}

.highlight-selected-row {
  background-color: #edf5fe;
}
