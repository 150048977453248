.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  line-height: 140%;
  color: #434e6b;
}

.container h1 {
  font-weight: bold;
  margin-bottom: 0;
}

.container p {
  font-weight: 400;
}
