.active,
.non-active {
  padding: 0.25rem 1.5rem;
  text-transform: capitalize;
}

.active {
  background-color: #51a0f7;
  color: #fff;
  border-radius: 2px 0 0 2px;
}

.active:hover {
  background-color: #74b3f9;
}

.non-active {
  font-weight: 400;
  color: #434e6b;
  border: 1px solid #e0e0e0;
  border-radius: 0 2px 2px 0;
}
