.input-wrapper {
  margin: 0 0.5rem 1rem 0;
  width: 3.5rem;
}

.input {
  color: #434e6b;
  font-size: 0.875rem;
  padding: 0.4rem 0.7rem;
}

.input::placeholder {
  color: #939ba1;
  font-size: 0.875rem;
}

.input + fieldset {
  border-radius: 2px;
  border-color: #e0e0e0;
}

.input-wrapper .input:focus + fieldset {
  border: 1px solid #51a0f7;
}

.input-wrapper .disabled {
  -webkit-text-fill-color: #434e6b;
  background-color: #e0e0e0;
}

.input-wrapper .error {
  font-size: 0.75rem;
  margin: 2.25rem 0 0 0.5rem;
  color: #ff3541;
  position: absolute;
  line-height: 1;
}
