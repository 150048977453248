.table {
  display: grid;
}

.table > div[role='cell'] {
  height: 3rem;
  border-bottom: 1.5px solid #dfdfdf;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 0.5rem;
}

.table input {
  width: 100%;
}

.shadow-cell {
  border-right: 1.5px solid #dfdfdf;
}

.table > div[data-first-cell]:not([data-columnid='expander'], [data-columnid='selection']) {
  padding-left: 1rem;
}

.table > [data-columnid='expander'] {
  min-width: 45px;
}

.wrapper {
  max-width: 85vw;
  border-right: 1px solid #dfdfdf;
  border-left: 1px solid #dfdfdf;
  overflow: scroll;
  border-radius: 0.125rem;
  height: 100%;
}

.pagination {
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  width: 100%;
  position: sticky;
  bottom: 0;
  background: #fff;
  left: 0;
  z-index: 4;
}
