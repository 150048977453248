.title {
  width: 100%;
}

.table-header {
  padding: 0.5rem;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: sticky;
  background-color: #fff;
  min-height: 3rem;
}

.table-header:first-child:not([data-columnid='expander'], [data-columnid='selection']) {
  padding-left: 1rem;
}

.cell-border-right {
  margin-right: 0.5rem;
  border-right: 1px solid #e0e0e0;
}

.cell-border-left {
  border-left: 1px solid #e0e0e0;
}
