.drawer {
  width: 17rem;
  flex-shrink: 0;
}

.paper {
  background-color: #51a0f7;
  border: 0;
  justify-content: space-between;
  width: 17rem;
}

.link {
  text-decoration: none;
}

.logo {
  margin: 1.5rem;
}

.title {
  color: #dcecfd;
  padding-left: 1.5rem;
  padding-right: 1rem;
}
