@value input-bordered from "../InputBase/InputBase.module.css";

.input-styled {
  composes: input-bordered;
  padding: 8.5px 14px;
  display: flex;
  align-items: center;
}

.input-base {
  width: 100%;
  padding: 0;
  flex-grow: 1;
}

.disabled {
  background-color: #e0e0e0;
}
