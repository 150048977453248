.label,
.select {
  font-size: 0.875rem;
}

.select {
  border-radius: 2px;
}

.select + fieldset {
  border-color: #e0e0e0;
}

.disabled {
  background-color: #e0e0e0;
  border-radius: 2px;
}

.disabled + fieldset {
  border: 0;
}
