.container {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
  border-radius: 2px;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  height: 2.3rem;
}

.container:not(.disabled):hover {
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.87);
}

.content {
  display: flex;
  gap: 0.25rem;
  flex-grow: 1;
}

.icon {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.disabled {
  background-color: #e0e0e0;
  border: 0;
  cursor: default;
  color: rgba(0, 0, 0, 0.38);
}
