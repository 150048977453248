.render-value-container {
  display: flex;
  gap: 0.25rem;
}

.chip {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  height: 1.5rem;
}
