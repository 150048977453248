.render-value-container {
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
}

.item {
  padding: 0 0.25rem;
  height: 2rem;
  font-size: 0.875rem;
}

.chip {
  height: 1.5rem;
}

.title {
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 700;
  color: #434e6b;
  text-transform: uppercase;
}

.item span {
  font-size: 0.875rem;
}
