.wrapper {
  position: fixed;
  bottom: 0;
  left: 15.5rem;
  right: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
