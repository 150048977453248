.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
  padding: 1.5rem;
  background-color: #fff;
  box-shadow: 0 -1px 4px #dadada;
}

.selected {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.cancel-button,
.add-tags-button {
  margin-right: 1rem;
}
