.header {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-flow: wrap;
  padding: 1rem 0;
  width: 100%;
}

.container-column {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  width: 100%;
}
