.tooltip {
  background-color: #434e6b;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 400;
}

.tooltip-arrow {
  color: #434e6b;
}
