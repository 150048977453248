.label span {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

.label {
  font-size: 0.875rem;
}

.label-check {
  padding: 0 0.5rem 0 0;
  width: 20%;
}
