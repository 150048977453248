.red-button {
  background-color: #ff3541;
  margin-left: 1rem;
  transition: 0.1s;
}

.red-button:hover {
  background-color: #ff3541;
  opacity: 0.9;
}
