.field {
  margin-bottom: 1rem;
}

.comments-modal-input {
  width: 100%;
  height: 100px;
}

.comments-modal-error {
  color: red;
}
