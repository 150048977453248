.container {
  width: 13.5rem;
  background: #ff3541;
  border-radius: 2px;
  color: white;
  margin: 0.5rem auto;
  padding: 0.2rem 1rem;
}

.heading {
  font-weight: bold;
  font-size: 0.875rem;
  margin-bottom: 0;
}

.inner-container p {
  margin-top: 0;
}

.inner-container {
  display: flex;
  align-items: center;
  font-size: 0.625rem;
}

.loader svg {
  position: relative;
  left: 0.5rem;
  top: 1rem;
  width: 1.5rem;
}
