.input {
  font-family: Roboto, serif;
  color: #434e6b;
  font-size: 0.875rem;
  padding: 8.5px 14px;
  border: 0;
}

.input-bordered {
  border: 1px solid #e0e0e0;
  border-radius: 2px;
}

.input-bordered:focus {
  border-color: #51a0f7;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: #939ba1;
}

.input:disabled {
  background-color: #e0e0e0;
}

.success-border {
  border: 1px solid #86c64f;
}

.error-border {
  border: 1px solid #cf2030;
}
