.text {
  font-size: 0.875rem;
  color: #434e6b;
  margin-bottom: 2.5rem;
  line-height: 140%;
}

.actions {
  display: flex;
  justify-content: space-between;
}
