.upload-icon-button {
  border: 1px solid #51a0f7;
  height: 2rem;
  border-radius: 2px;
  color: #51a0f7;
  margin-right: 0.5rem;
}

.icon {
  font-size: 1.5rem;
}
