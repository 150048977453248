.default {
  font-size: 0.75rem;
  margin-top: 3.55rem;
  position: absolute;
  color: #ff3541;
}

.block {
  position: relative;
  display: block;
  margin: 0;
}
